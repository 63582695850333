import React, { useEffect, useState } from 'react';
import Kategorie from "./struktur/Kategorie";
import {fetchBildungsplanerData} from "../api/crm-api";

const Bildungsplaner = ({handleClick, setSelectedItem, initialKursId, setInitialKursId}) => {

    useEffect(() => {
        console.log('initialKursId is ', initialKursId);
    }, [initialKursId]);

    const [bildungsplanerData, setBildungsplanerData] = useState([]);

    const findeKursRekursiv = (data, courseId) => {
        for (const item of data) {
            if (item.id === courseId && item.type === "course") {
                return item;
            }
            if (item.items && item.items.length > 0) {
                const result = findeKursRekursiv(item.items, courseId);
                if (result) return result;
            }
        }
        return null;
    };

    useEffect(() => {
        const initBildungsplanerData = async () => {
            try {
                const data = await fetchBildungsplanerData();
                setBildungsplanerData(data);
                if (initialKursId && initialKursId !== '') {
                    const kurs = findeKursRekursiv(data, parseInt(initialKursId));
                    if (kurs) {
                        setSelectedItem(kurs);
                        setInitialKursId(null);
                        console.log('Kurs gefunden:', kurs);
                    } else {
                        console.warn(`Kurs mit ID ${initialKursId} nicht gefunden.`);
                    }
                }
                setInitialKursId(null);
                console.log(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        initBildungsplanerData();
    }, []);

    return (
        <div className="bpMain">
            {
                [1,2,3,4].map((col)=>(
                    <div className={"bpColumn bpc"+col} key={col}>
                    {
                        bildungsplanerData.filter((bildungsplanerEintrag) => bildungsplanerEintrag.column === col).map(
                            (bildungsplanerEintrag) => (
                                <Kategorie
                                    title={bildungsplanerEintrag.name}
                                    color={bildungsplanerEintrag.color}
                                    groups={bildungsplanerEintrag.items}
                                    key={bildungsplanerEintrag.name}
                                    handleClick={handleClick}/>
                            )
                        )
                    }
                    </div>
                ))
            }
        </div>
    );
};

export default Bildungsplaner;
