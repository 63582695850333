import apiConfig from './crm-api-config';

export async function fetchBildungsplanerData() {
    try {
        const response = await fetch(`${apiConfig.apiRoot}/hierarchical_categories`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}
