import Thema from "./Thema";

const Gruppe = ({title, themes, handleClick})=>{
    return(
        <div className="bpGroup">
            {
                title !== null &&
                title !== undefined &&
                <div className="planer-line planer-group-title">
                    <p>{title}</p>
                </div>
            }
            {
                (themes || []).map((theme) => (
                    <Thema
                        title={theme.name}
                        entries={theme.items}
                        key={theme.name}
                        handleClick={handleClick}/>
                ))
            }
        </div>
    );
};

export default Gruppe;
