import {useEffect} from "react";

import SkeletonKurs from "./SkeletonKurs";

const HtmlKurs = ({html, kursId, kursTitle, kursColor, fileName, fileUrl, resetClick}) => {

    useEffect(() => {
        // console.log('sending event');
        window.top.postMessage({ action: "kursChanged", data: kursId }, "https://www.idm.ch");
        window.top.postMessage({ action: "kursChanged", data: kursId }, "https://idm.stage.mxm.ch");
    }, [kursId])

    return (
        <SkeletonKurs
            kursId={kursId}
            kursTitle={kursTitle}
            kursColor={kursColor}
            fileName={fileName}
            fileUrl={fileUrl}
            resetClick={resetClick}>

            <div dangerouslySetInnerHTML={{__html: html}}/>

        </SkeletonKurs>
    );
};

export default HtmlKurs;
