import Gruppe from "./Gruppe";

const Kategorie = ({title, color, groups, handleClick}) => {
    const categoryStyle = {
        '--cat-color': color,
    };

    return(
        <div className="bpCategory" style={categoryStyle}>
            {title != null &&
            <div className="planer-line planer-category-title">
                <p>{title}</p>
            </div>}
            {groups.map((group) => (
                <Gruppe
                    title={group.name}
                    themes={group.items}
                    key={group.name}
                    handleClick={handleClick}/>
            ))}
        </div>
    );
};

export default Kategorie;
