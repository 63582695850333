import './App.css';
import Bildungsplaner from './components/Bildungsplaner';
import {useState} from 'react'
import EventoKurs from './components/struktur/kurse/EventoKurs';
import HtmlKurs from './components/struktur/kurse/HtmlKurs';
import apiConfig from "./api/crm-api-config";

function App({kursId}) {
    const [selectedItem, setSelectedItem] = useState(null);
    const [initialKursId, setInitialKursId] = useState(kursId);

    function handleClick(entry) {
        setSelectedItem(entry);
    }

    function resetClick() {
        setInitialKursId(null);
        setSelectedItem(null);
        window.location = '/';
    }

    return (
        <>
            {selectedItem ? (selectedItem.evento_id !== null ? (
                <EventoKurs
                    kursId={selectedItem.id}
                    eventoId={selectedItem.evento_id}
                    kursTitle={selectedItem.name}
                    kursColor={selectedItem.color}
                    fileName={selectedItem ? selectedItem.file_name : null}
                    fileUrl={selectedItem ? `${apiConfig.uploadsRoot}/${selectedItem.id}/${selectedItem.file_name}` : null}
                    resetClick={resetClick}
                />
            ) : (
                <HtmlKurs
                    kursId={selectedItem.id}
                    html={selectedItem.html_text}
                    kursTitle={selectedItem.name}
                    kursColor={selectedItem.color}
                    fileName={selectedItem ? selectedItem.file_name : null}
                    fileUrl={selectedItem ? `${apiConfig.uploadsRoot}/${selectedItem.id}/${selectedItem.file_name}` : null}
                    resetClick={resetClick}
                />
            )) : (
                <Bildungsplaner
                    handleClick={handleClick}
                    setSelectedItem={setSelectedItem}
                    initialKursId={kursId}
                    setInitialKursId={setInitialKursId}
                />
            )}
        </>
    );
}

export default App;
