const SkeletonKurs = ({kursTitle, kursColor, resetClick, fileName, fileUrl, children})=>{
    const courseStyle = {
        '--course-color': kursColor,
    };

    return(
        <div className="KursContainer" style={courseStyle}>
            <div className="inner-container">
                <h2 className="title" onClick={resetClick}>
                    {kursTitle}
                    <span className="backButton">
                        ✖
                    </span>
                </h2>
                <div className="kurs-content">
                    {children}
                </div>
                {
                    (fileName && fileUrl) &&
                    <>
                        <div className="download-container">
                            <a href={fileUrl} target={'_blank'} download={fileName}>
                                Detaillierte Kursinformationen herunterladen
                            </a>
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default SkeletonKurs;
