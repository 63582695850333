import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const urlParams = new URLSearchParams(window.location.search);
const kursId = urlParams.get('kursId');
console.log(kursId);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App kursId={kursId} />
  </React.StrictMode>
);
