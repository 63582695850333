import {useEffect} from "react";

import SkeletonKurs from "./SkeletonKurs";

const EventoKurs = ({eventoId, kursId, kursTitle, kursColor, fileName, fileUrl, resetClick}) => {

    useEffect(() => {
        // console.log('sending event');
        window.top.postMessage({ action: "kursChanged", data: kursId }, "https://www.idm.ch");
        window.top.postMessage({ action: "kursChanged", data: kursId }, "https://idm.stage.mxm.ch");
    }, [kursId])

    const rawHtml = 'eventoapp.apps.be.ch/restApi hat die Verbindung abgelehnt (CORS)';

    return (
        <SkeletonKurs
            kursId={kursId}
            kursTitle={kursTitle}
            kursColor={kursColor}
            fileName={fileName}
            fileUrl={fileUrl}
            resetClick={resetClick}>

            <iframe src={`https://www.idm.ch/idm/media/kursausschreibung/?navigation=hide#/uid/${eventoId}`} className="evento-kurs-iframe">
                <a href={`https://www.idm.ch/idm/media/kursausschreibung/?navigation=hide#/uid/${eventoId}`}>
                    Kurs ID {eventoId}
                </a>
            </iframe>


        </SkeletonKurs>
    );
};

export default EventoKurs;
