import { useState } from "react";

const Thema = ({title, entries, handleClick})=>{

    const [isOpen, setOpen] = useState(false);

    function toggleOpen(){
        setOpen(!isOpen);
    }

    if(entries.length === 0){
        return(
            <div className="planer-theme-grouping">
                <div className="planer-line planer-entry planer-entry-disabled">
                    <p>{title}<span className="planer-line-addition">(0)</span></p>
                </div>
            </div>
        );
    } 
    else if(entries.length === 1){
        return(
            <div className="planer-theme-grouping">
                <div className="planer-line planer-entry" key={entries[0].id} onClick={()=>handleClick(entries[0])}>
                    <p>{title}<span className="planer-line-addition">({entries.length})</span></p>
                </div>
            </div>
        );
    }

    return(
        <div className="planer-theme-grouping">
            <div className={"planer-line planer-entry planer-entry-expandable" + (isOpen ? " expanded" : "")} onClick={toggleOpen}>
                <p>{title}<span className="planer-line-addition">({entries.length})</span></p>
            </div>
            {entries.map((entry)=>(
                <div className={"planer-line planer-entry planer-entry-subpoint "+ (isOpen?"":"planer-entry-subpoint-hidden")} onClick={()=>handleClick(entry)}>
                    <p>{entry.name}</p>
                </div>
            ))}
        </div>
    );
};

export default Thema;
